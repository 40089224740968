<template>

    <div class="page-content">
        <div class="container-fluid">

            <Breadcrumb
                :pgTitle="$t('nav.coupons')"
                :pgIcon="'bx bx-money'"
                :refs="refs"
                :pgCurrent="pgCurrent"
                :addNew="false">
            </Breadcrumb>

            <div class="row">

                <Loading
                    v-if="pgLoading">
                </Loading>

                <div v-if="!pgLoading" class="col-12" 
                    style="margin-right: -25px;padding:0;text-align: left;margin-bottom: 10px;">
                    <a 
                        href="javascript:;" 
                        class="col-1 btn btn-danger" 
                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                        :style="($i18n.locale == 'ar') ? '' : 'text-align: left'"
                        v-print="'#printMe'">{{ $t('app.print') }}  
                    </a>
                </div>

                <div v-if="!pgLoading" class="col-12 row" id="printMe">
                    <div
                        v-for="(code, idx) in codes"
                        :key="idx"
                        class="col-1 text-center"
                        :class="(code['status']) ? 'disabled' : ''"
                        style="border:1px solid #ddd; padding: 5px; font-size: 16px; cursor: pointer;">
                        {{ code['code'] }}
                    </div>
                </div>

            </div>

        </div>
    </div>

</template>

<script>
import iziToast from 'izitoast';

export default {
    name: 'EditOrNew',
    components: {
        Breadcrumb: () => import('@/components/Breadcrumb.vue'),
        Loading: () => import('@/components/Loading.vue')
    },
    data(){
        return {    
            // auth
            auth: {
                role: '',
                access_token: '',
            },

            codes: [],
                
            msgCurrent: (this.$route.params.id) ? 'Updated' : 'Created',
            btnCurrent: (this.$route.params.id) ? 'Update' : 'Create',
            methodType: (this.$route.params.id) ? 'PUT' : 'POST',
            pgLoading: (this.$route.params.id) ? true : false,
            pgCurrent: (this.$route.params.id) ? 'Edit' : 'Add New',
            btnLoading: false,
            
            refs: 'coupons',
        }
    },
    mounted() {},
    created() {
        // AccessToken & Role
        if(localStorage.getItem('access_token')) {
            this.auth.access_token = localStorage.getItem('access_token');
        }
        if(localStorage.getItem('role')) {
            this.auth.role = localStorage.getItem('role');
        }

        if(this.$route.params.id) {
            this.fetchRow();
        }
    },
    methods: {
        
        // fetch Row
        fetchRow() {
            this.pgLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const options = {
                url: window.baseURL+'/'+this.refs+'/'+this.$route.params.id,
                method: 'GET',
                data: {}
            }
            this.axios(options)
            .then(res => {
                this.pgLoading = false;
                this.codes = res.data.row.codes;
            })
            .catch(err => {

                // 403 Forbidden
                if(err.response && err.response.status == 401) {
                    this.clearLocalStorage();
                    this.$router.push({ name: 'login' });
                } else if(err.response && err.response.status == 403) {
                    this.$router.push({ name: 'forbidden' });
                } else {
                    this.btnLoading = false;
                    iziToast.warning({
                        icon: 'bx bx-angry',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });
                }

            })
            .finally(() => {});
        },
            
          
        // clear Local Storage
        clearLocalStorage() {
            localStorage.removeItem('access_token');
            localStorage.removeItem('avatar');
            localStorage.removeItem('username');
            localStorage.removeItem('encrypt_id');
            localStorage.removeItem('role');
        },

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    div.disabled {
        background: #b7b7b7;
        cursor: auto !important;
    }
</style>
